import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { StatusDetails } from './StatusDetails';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
    minWidth: '350px',
    background: '#CBD5DD',
    color: '#4A4A4A',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '18px',
    lineHeight: '22px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#213845',
  },
  dialogText: {
    fontSize: 14,
    lineHeight: '20px',
    color: '#4A4A4A',
  },
  grid: {
    paddingTop: theme.spacing(1),
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 14,
    lineHeight: '20px',
    color: '#4A4A4A',
  },
  details: {
    fontSize: 12,
    lineHeight: '16px',
    color: '#4A4A4A',
  },
  modalButtons: {
    lineHeight: '17px',
    textTransform: 'none',
  },
}));

export function ActivationConfirmationDialog(props) {
  const classes = useStyles();
  const { open, onClose, onConfirm, userData } = props;
  let firstName, lastName, emailID, userType;
  if (userData) {
    ({ firstName, lastName, emailID, userType } = userData);
  }
  const confirm = () => {
    onConfirm(userData);
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm'>
      <DialogTitle className={classes.title}>
        <Typography variant='h6'>Activate User</Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <div className={classes.dialogText}>
            Are you sure you want to activate the user
          </div>

          <Grid container>
            <Grid item xs={2} className={classes.grid}>
              <StatusDetails userData={userData} />
            </Grid>
            <Grid item>
              <div className={classes.name}>
                {firstName} {lastName}{' '}
              </div>
              <div className={classes.details}>
                <div>{userType}</div>
                <div>{emailID}</div>
              </div>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant='contained'
          disableElevation
          className={classes.modalButtons}
        >
          Cancel
        </Button>

        <Button
          onClick={confirm}
          variant='contained'
          className={classes.modalButtons}
          color='primary'
          disableElevation
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ActivationConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    emailID: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired,
  }),
};
