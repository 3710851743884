import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Title } from '../Header';
import { AnalyticsIcon } from '../Header/Icons';
import Chip from '@material-ui/core/Chip';
import { useViewDetails } from '../useViewDetails';
import { TableauMarkSelectionView } from '../TableauView';
import { AgencyAnalyticsSkeleton } from './AgencyAnalyticsSkeleton';
import { AgencyAnalyticsError } from './AgencyAnalyticsError';
import {
  Route,
  useRouteMatch,
  useHistory,
  Switch,
  useLocation,
  matchPath,
  Redirect,
} from 'react-router-dom';
import { AgencyDetails } from '../AgencyDetails/AgencyDetails';
import { getAgencyNamesFromMarks } from '../TableauView/getAgencyNameFromMarks';
import { useTenantDetails } from '../Header/useTenantDetails';
import Skeleton from '@material-ui/lab/Skeleton';
import { getApplyButtonFromMarks } from '../TableauView/getApplyButtonFromMarks';
import { tenantLabels } from '../TableauView/tenantLabels';
import { UserContext } from '../UserContext';

const useStyles = makeStyles((theme) => ({
  agencyCountText: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '19px',
    color: '#4A4A4A',
    paddingTop: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
  },
  agencyCount: {
    borderRadius: 0,
    marginLeft: theme.spacing(1),
    color: '#2D6089',
  },
}));

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

const AGENCY_ANALYTICS_KEY = 'Agency Analytics';

const AgencyAnalytics = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();
  const { pathname: pathName } = useLocation();
  const AGENCY_ANALYTICS_PATH = '/home/agencyanalytics';
  const apiPath = '/api/metrics/v1/agencyTable';
  const loadAnalytics = matchPath(pathName, AGENCY_ANALYTICS_PATH).isExact;
  const details = useViewDetails(apiPath, loadAnalytics);
  const { fetching, success, error, data } = details;
  const { user } = useContext(UserContext);
  const tenantApi = user.getTenantApi();

  let agencyCount;
  const { fetching: countFetching, data: tenantDetails } = useTenantDetails();
  if (tenantDetails) {
    ({ agencyCount } = tenantDetails);
  }

  const isAgencyAnalyticsHidden =
    tenantApi.isFunctionalityHidden(AGENCY_ANALYTICS_KEY);
  if (isAgencyAnalyticsHidden) {
    const redirectionPath = tenantApi.getRedirectionPath();
    return <Redirect to={redirectionPath} />;
  }

  if (data && tenantDetails) {
    const { filter } = data;
    const { carrierLabel } = tenantDetails;
    let modifiedFilter;
    const parsedCarrierLabel = carrierLabel.replace(
      /[-/\\^$,*+?.()|[\]{}]/g,
      '\\$&'
    );

    if (typeof filter === 'string') {
      if (filter === '') {
        modifiedFilter = `${tenantLabels.CARRIERLABEL}=${parsedCarrierLabel}`;
      } else {
        modifiedFilter = `${filter}&${tenantLabels.CARRIERLABEL}=${parsedCarrierLabel}`;
      }
    }
    data.filter = modifiedFilter;
  }

  const goToAgencyDetails = (agencyName) => {
    history.push(`${match.url}/agencydetails/${agencyName}`);
  };

  const onSelect = async (marks, worksheetName, viz) => {
    const isApplyClick = getApplyButtonFromMarks(marks)[0];
    if (isApplyClick === undefined) {
      const agencyName = getAgencyNamesFromMarks(marks)[0];
      if (agencyName) {
        goToAgencyDetails(agencyName);
      }
    }
  };

  const left = (
    <>
      {countFetching && <Skeleton variant="rect" height={25} width={35} />}
      {agencyCount && (
        <div className={classes.agencyCountText}>
          Total Agency Count
          <Chip
            label={agencyCount}
            size="small"
            className={classes.agencyCount}
          />
        </div>
      )}
    </>
  );

  return (
    <>
      <Switch>
        <Route path={`${match.path}/agencydetails/:agencyName`}>
          <AgencyDetails backPath={match.url} />
        </Route>
        <Route path={`${match.path}`}>
          <Title
            icon={AnalyticsIcon}
            titleText={AGENCY_ANALYTICS_KEY}
            left={left}
          />
          {fetching && <AgencyAnalyticsSkeleton />}
          {success && (
            <TableauMarkSelectionView
              {...data}
              {...dimensions}
              adjustHeight={true}
              onSelect={onSelect}
            />
          )}
          {error && <AgencyAnalyticsError />}
        </Route>
      </Switch>
    </>
  );
};

export { AgencyAnalytics };
