import React from 'react';
import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from 'material-table';
import { ValidationErrorDialog } from './ValidationErrorDialog';
import { ConfirmationDialog } from './ConfirmationDialog';
import { ActivationConfirmationDialog } from './ActivationConfirmationDialog';
import { EnableUserConfirmationDialog } from './EnableUserConfirmationDialog';
import { DisableUserConfirmationDialog } from './DisableUserConfirmationDialog';
import { SendActivationEmailConfirmationDialog } from './SendActivationEmailConfirmationDialog';
import icons from './icons';
import { getColumns } from './columns';
import { validateEditedRow } from './validate';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ControlPointIcon from '@material-ui/icons/ControlPoint';

const useStyles = makeStyles((theme) => ({
  titleText: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  buttonParent: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(2),
  },
  button: {
    textTransform: 'none',
  },
}));

const isEditable = (rowData) => {
  return !!rowData.userID;
};

export function UserList(props) {
  const classes = useStyles();
  const {
    users,
    updateUser,
    newUser,
    changeUserDetails,
    activateUser,
    enableUser,
    disableUser,
    sendActivationEmail,
    loading,
    guide,
    userEmail,
    userRole,
  } = props;
  const addActionRef = React.useRef();

  const [validationMessage, setValidationMessage] = useState({
    error: false,
    errorMessages: [],
  });
  const [confirmationMessage, setConfirmationMessage] = useState({
    visible: false,
    userData: {},
  });
  const [activationConfirmationMessage, setActivationConfirmationMessage] =
    useState({
      visible: false,
      userData: {},
    });
  const [disableUserConfirmationMessage, setDisableUserConfirmationMessage] =
    useState({
      visible: false,
      userData: {},
    });
  const [enableUserConfirmationMessage, setEnableUserConfirmationMessage] =
    useState({
      visible: false,
      userData: {},
    });
  const [
    sendActivationEmailConfirmationMessage,
    setSendActivationEmailConfirmationMessage,
  ] = useState({
    visible: false,
    userData: {},
  });
  const columns = getColumns(users, userEmail, userRole);

  const { error: submitError, errorMessages } = validationMessage;
  const { visible: confirmationVisible, userData: confirmationUpdateData } =
    confirmationMessage;
  const {
    visible: activationConfirmationVisible,
    userData: activationConfirmationUserData,
  } = activationConfirmationMessage;
  const {
    visible: disableUserConfirmationMessageVisible,
    userData: disableUserConfirmationUserData,
  } = disableUserConfirmationMessage;
  const {
    visible: enableUserConfirmationMessageVisible,
    userData: enableUserConfirmationUserData,
  } = enableUserConfirmationMessage;
  const {
    visible: sendActivationEmailConfirmationMessageVisible,
    userData: sendActivationEmailConfirmationUserData,
  } = sendActivationEmailConfirmationMessage;

  const removeValidationMessage = () => {
    setValidationMessage({ error: false, errorMessages: [] });
  };
  const removeConfirmationMessage = () => {
    setConfirmationMessage({ visible: false, userData: {} });
  };
  const removeActivationConfirmationMessage = () => {
    setActivationConfirmationMessage({ visible: false, userData: {} });
  };
  const removeDisableUserConfirmationMessage = () => {
    setDisableUserConfirmationMessage({ visible: false, userData: {} });
  };
  const removeEnableUserConfirmationMessage = () => {
    setEnableUserConfirmationMessage({ visible: false, userData: {} });
  };
  const removeSendActivationEmailConfirmationMessage = () => {
    setSendActivationEmailConfirmationMessage({ visible: false, userData: {} });
  };

  const onRowUpdate = async (newData, oldData) => {
    const isEdit = oldData.hasBasicDetails;
    const { error, errorMessages } = validateEditedRow(newData);
    if (error) {
      setValidationMessage({
        error,
        errorMessages,
      });
      throw new Error();
    } else {
      setConfirmationMessage({
        visible: true,
        userData: { isEdit, oldData, newData, isNew: false },
      });
    }
  };
  const onRowAdd = async (newData) => {
    const { error, errorMessages } = validateEditedRow(newData);
    if (error) {
      setValidationMessage({
        error,
        errorMessages,
      });
      throw new Error();
    } else {
      setConfirmationMessage({
        visible: true,
        userData: { isEdit: false, oldData: {}, newData, isNew: true },
      });
    }
  };

  const onUserActivation = async (event, userData) => {
    setActivationConfirmationMessage({
      visible: true,
      userData,
    });
  };
  const onUserDisable = async (event, userData) => {
    setDisableUserConfirmationMessage({
      visible: true,
      userData,
    });
  };
  const onUserEnable = async (event, userData) => {
    setEnableUserConfirmationMessage({
      visible: true,
      userData,
    });
  };

  const onAddUser = () => addActionRef.current.click();

  const onSendActivationEmail = async (event, userData) => {
    setSendActivationEmailConfirmationMessage({
      visible: true,
      userData,
    });
  };

  return (
    <Fragment>
      {submitError && (
        <ValidationErrorDialog
          open={submitError}
          errorMessages={errorMessages}
          onClose={removeValidationMessage}
        />
      )}
      {confirmationVisible && (
        <ConfirmationDialog
          open={confirmationVisible}
          updateData={confirmationUpdateData}
          onClose={removeConfirmationMessage}
          onConfirm={updateUser}
          onChangeConfirm={changeUserDetails}
          onNewConfirm={newUser}
          users={users}
        />
      )}
      {activationConfirmationVisible && (
        <ActivationConfirmationDialog
          open={activationConfirmationVisible}
          userData={activationConfirmationUserData}
          onClose={removeActivationConfirmationMessage}
          onConfirm={activateUser}
        />
      )}
      {disableUserConfirmationMessageVisible && (
        <DisableUserConfirmationDialog
          open={disableUserConfirmationMessageVisible}
          userData={disableUserConfirmationUserData}
          onClose={removeDisableUserConfirmationMessage}
          onConfirm={disableUser}
        />
      )}
      {enableUserConfirmationMessageVisible && (
        <EnableUserConfirmationDialog
          open={enableUserConfirmationMessageVisible}
          userData={enableUserConfirmationUserData}
          onClose={removeEnableUserConfirmationMessage}
          onConfirm={enableUser}
        />
      )}
      {sendActivationEmailConfirmationMessageVisible && (
        <SendActivationEmailConfirmationDialog
          open={sendActivationEmailConfirmationMessageVisible}
          userData={sendActivationEmailConfirmationUserData}
          onClose={removeSendActivationEmailConfirmationMessage}
          onConfirm={sendActivationEmail}
        />
      )}
      {guide && (
        <Typography variant='h6' className={classes.titleText}>
          Invite a few team members to get started
        </Typography>
      )}

      <div className={classes.buttonParent}>
        <Button
          disableElevation
          variant='contained'
          color='primary'
          className={classes.button}
          startIcon={<ControlPointIcon />}
          onClick={onAddUser}
        >
          {users.length === 0 && 'Add & Invite User'}
          {users.length > 0 && 'Add User'}
        </Button>
      </div>
      <MaterialTable
        isLoading={loading}
        columns={columns}
        components={{
          Action: (props) => {
            if (
              typeof props.action === typeof Function ||
              props.action.tooltip !== 'Add'
            ) {
              return <MTableAction {...props} />;
            } else {
              return <div ref={addActionRef} onClick={props.action.onClick} />;
            }
          },
        }}
        actions={[
          (rowData) => ({
            icon: icons.VerifyUser,
            tooltip: 'Activate User',
            onClick: onUserActivation,
            hidden: !(
              rowData.hasBasicDetails && !rowData.graviteeProfileCreated
            ),
          }),
          (rowData) => ({
            icon: icons.BlockUser,
            tooltip: 'Disable',
            onClick: onUserDisable,
            hidden: !rowData.graviteeProfileActivated,
          }),
          (rowData) => ({
            icon: icons.UnblockUser,
            tooltip: 'Enable',
            onClick: onUserEnable,
            hidden: !(
              rowData.graviteeProfileCreated &&
              !rowData.graviteeProfileActivated
            ),
          }),
          (rowData) => ({
            icon: icons.ActivationEmail,
            tooltip: 'Resend Activation Email',
            onClick: onSendActivationEmail,
            hidden: !rowData.graviteeProfileActivated,
          }),
        ]}
        data={users}
        icons={icons}
        options={{
          grouping: true,
          columnsButton: true,
          searchFieldAlignment: 'left',
          showTitle: false,
          addRowPosition: 'first',
        }}
        editable={{
          isEditable: isEditable,
          onRowUpdate: onRowUpdate,
          onRowAdd: onRowAdd,
        }}
        style={{
          padding: 0,
          overflow: 'hidden',
          fontFamily: 'Barlow',
        }}
        localization={{
          header: {
            actions: '',
          },
        }}
      />
    </Fragment>
  );
}

UserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({ userID: PropTypes.number.isRequired })
  ).isRequired,
  updateUser: PropTypes.func.isRequired,
  newUser: PropTypes.func.isRequired,
  changeUserDetails: PropTypes.func.isRequired,
  activateUser: PropTypes.func.isRequired,
  linkUser: PropTypes.func.isRequired,
  disableUser: PropTypes.func.isRequired,
  enableUser: PropTypes.func.isRequired,
  sendActivationEmail: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  guide: PropTypes.bool,
  userEmail: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
};
