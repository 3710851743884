import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from './../UserContext';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useUserList } from './../userUserList';
import { ProfileSkeleton } from './ProfileSkeleton';
import { UserListFetchError } from './UserListFetchError';
import {
  useUserAction,
  actionTypes,
  actionTypeFailureMessages,
  filterUsers,
} from './../useUserAction';
import { CompleteProfileForm } from './CompleteProfileForm';
import { ProfileCompletedMessage } from './ProfileCompletedMessage';
import { Header } from './../Settings';
import Container from '@material-ui/core/Container';
import { SkipCompleteProfile } from './SkipCompleteProfile';
import { GuideStepper } from './../Guide';
import { AppLogo } from './../Guide';
import { useHistory } from 'react-router-dom';

export function CompleteProfile(props) {
  const { guide } = props;
  const { user } = useContext(UserContext);
  const currentUserID = user.getTenantLevelUserId();
  const history = useHistory();
  const [toRefresh, setToRefresh] = useState(true);
  const clearToRefresh = () => {
    setToRefresh(false);
  };
  const setRefresh = () => {
    setToRefresh(true);
  };
  const { fetching, error, success, data } = useUserList(
    toRefresh,
    clearToRefresh
  );
  let profileCompleted = false;
  if (success && data.users) {
    const currentUserDetails = data.users.find(
      (user) => user.userID === currentUserID
    );
    if (currentUserDetails && currentUserDetails.hasBasicDetails) {
      profileCompleted = true;
    }
  }
  const [action, setAction] = useState({
    type: undefined,
    payload: undefined,
  });
  const {
    fetching: actionFetching,
    error: actionError,
    success: actionSuccess,
  } = useUserAction(action, setRefresh);
  useEffect(() => {
    if (actionSuccess) {
      if (guide) {
        history.push('/home/settings/users/guide');
      } else {
        window.location.href = '/api/auth/authorization-code/callback';
      }
    }
  }, [guide, history, actionSuccess]);
  const linkUser = (userDetails) => {
    setAction({
      type: actionTypes.LINK,
      payload: userDetails,
    });
  };
  const completeProfile = (userDetails) => {
    setAction({
      type: actionTypes.COMPLETE_PROFILE,
      payload: userDetails,
    });
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  useEffect(() => {
    if (actionError) {
      setSnackbarOpen(actionError);
    }
  }, [actionError]);
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  let headerActions;
  let headerCenterContent;
  let leftContent;
  if (guide) {
    headerActions = <SkipCompleteProfile />;
    headerCenterContent = <GuideStepper activeStep={1} />;
    leftContent = <AppLogo />;
  }
  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert severity='error'>{actionTypeFailureMessages[action.type]}</Alert>
      </Snackbar>
      <Header
        title='Complete Profile'
        actions={headerActions}
        hideBackButton={guide}
        centerContent={headerCenterContent}
        leftContent={leftContent}
      />
      <Container maxWidth='sm'>
        {(fetching || actionFetching) && <ProfileSkeleton />}
        {error && <UserListFetchError />}
        {success && !profileCompleted && (
          <CompleteProfileForm
            guide={guide}
            users={filterUsers(data.users)}
            linkUser={linkUser}
            completeProfile={completeProfile}
            loading={actionFetching}
          />
        )}
        {success && profileCompleted && (
          <ProfileCompletedMessage guide={guide} submitLabel='Next' />
        )}
      </Container>
    </>
  );
}

CompleteProfile.propTypes = {
  guide: PropTypes.bool.isRequired,
};
