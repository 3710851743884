import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import buildUrl from 'build-url';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  disabled: {
    pointerEvents: 'none',
  },
  alignLock: {
    textAlign: 'center',
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.primary.dark,
    opacity: 0.95,
    zIndex: theme.zIndex.mobileStepper,
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: theme.spacing(5),
    color: theme.palette.primary.contrastText,
  },
  root: {
    width: '100%',
    position: 'relative',
  },
}));

export function TableauMarkSelectionView(props) {
  const [isInitialized, setIsInitialized] = useState(false);
  const [interactive, setInteractive] = useState(false);
  const containerRef = createRef();
  const classes = useStyles();
  const {
    placeholderWidth,
    placeholderHeight,
    vizWidth,
    vizHeight,
    hostUrl,
    siteRoot,
    vizName,
    tabs,
    toolbar,
    showAppBanner,
    embed,
    ticket,
    filter,
    device,
    onSelect,
    onFirstInteraction,
    adjustHeight,
    disabled,
    onFilter,
    overlay,
  } = props;
  const placeholderStyle = {
    display: 'flex',
    justifyContent: 'center',
    width: placeholderWidth,
    minHeight: placeholderHeight,
  };
  const params = {
    ':tabs': tabs,
    ':toolbar': toolbar,
    ':showAppBanner': showAppBanner,
    ':embed': embed,
    ':device': 'desktop',
  };
  if (device) {
    params[':device'] = device;
  }
  let filterParams = {};
  if (filter) {
    filterParams = queryString.parse(filter);
  }
  const url = buildUrl(hostUrl, {
    path: `/trusted/${ticket}${siteRoot}/views/${vizName}`,
    queryParams: {
      ...params,
      ...filterParams,
    },
  });
  const hasOverlay = typeof overlay === 'object';
  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
      const containerDiv = containerRef.current;
      let viz;
      const reportSelectedMarks = (marks, worksheetName) => {
        onSelect(marks, worksheetName, viz);
      };
      const onMarksSelection = async (marksEvent) => {
        const worksheetName = marksEvent.getWorksheet().getName();
        const selectedMarks = await marksEvent.getMarksAsync();
        return reportSelectedMarks(selectedMarks, worksheetName);
      };
      const listenToMarksSelection = () => {
        viz.addEventListener(
          window.tableau.TableauEventName.MARKS_SELECTION,
          onMarksSelection
        );
      };
      const reportSelectedFilters = (filter) => {
        onFilter(filter);
      };
      const listenToFilterSelection = () => {
        viz.addEventListener(
          window.tableau.TableauEventName.FILTER_CHANGE,
          onFilterSelection
        );
      };
      const listenToFirstInteraction = () => {
        onFirstInteraction(viz);
      };
      const onFilterSelection = async (filterEvent) => {
        const worksheet = filterEvent.getWorksheet();
        const filters = await worksheet.getFiltersAsync();
        return reportSelectedFilters({
          worksheetName: worksheet.getName(),
          filters: filters,
        });
      };
      const autoHeightAdjust = () => {
        const size = viz.getVizSize();
        const { sheetSize } = size;
        const { behavior, maxSize } = sheetSize;
        if (behavior === 'automatic') {
        } else if (maxSize) {
          const { width, height } = maxSize;
          viz.setFrameSize(width, height);
        }
      };
      const options = {
        height: vizHeight,
        width: vizWidth,
        onFirstInteractive: () => {
          setInteractive(true);
          if (onSelect) {
            listenToMarksSelection();
          }
          if (adjustHeight) {
            autoHeightAdjust();
          }
          if (onFilter) {
            listenToFilterSelection();
          }
          if (onFirstInteraction) {
            listenToFirstInteraction();
          }
        },
      };
      viz = new window.tableau.Viz(containerDiv, url, options);
    }
  }, [
    isInitialized,
    url,
    containerRef,
    vizHeight,
    vizWidth,
    onSelect,
    onFirstInteraction,
    adjustHeight,
    onFilter,
  ]);
  return (
    <div className={classes.root}>
      {disabled && (
        <div className={classes.alignLock}>
          <LockIcon fontSize="large" />
          <Typography variant="h6" fontWeight="fontWeightBold">
            This feature is not available in your current plan
          </Typography>
        </div>
      )}
      {interactive && hasOverlay && overlay}
      <div
        className={clsx({ [classes.disabled]: disabled })}
        style={placeholderStyle}
        ref={containerRef}
      ></div>
    </div>
  );
}

TableauMarkSelectionView.propTypes = {
  placeholderWidth: PropTypes.string.isRequired,
  placeholderHeight: PropTypes.string.isRequired,
  vizWidth: PropTypes.string.isRequired,
  vizHeight: PropTypes.string.isRequired,
  hostUrl: PropTypes.string.isRequired,
  siteRoot: PropTypes.string.isRequired,
  vizName: PropTypes.string.isRequired,
  tabs: PropTypes.oneOf(['yes', 'no']).isRequired,
  toolbar: PropTypes.oneOf(['yes', 'no']).isRequired,
  showAppBanner: PropTypes.oneOf(['true', 'false']).isRequired,
  embed: PropTypes.oneOf(['yes', 'no']).isRequired,
  ticket: PropTypes.string.isRequired,
  filter: PropTypes.string,
  device: PropTypes.oneOf(['desktop', 'tablet', 'phone']),
  onSelect: PropTypes.func,
  onFirstInteraction: PropTypes.func,
  adjustHeight: PropTypes.bool,
  disabled: PropTypes.bool,
  overlay: PropTypes.object,
};
