import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import Chip from '@material-ui/core/Chip';
import UpdateIcon from '@material-ui/icons/Update';
import { parseISO, formatDistanceToNow } from 'date-fns';
import Tooltip from '@material-ui/core/Tooltip';
import { useTenantDetails } from './useTenantDetails';
import TitleSkeleton from './TitleSkeleton';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  left: {
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  icon: {
    color: '#2D6089',
  },
  titleText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 22,
    color: '#4A4A4A',
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
  },
  verticalLine: {
    Stroke: 'Solid',
  },
  count: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#8D72CA',
    alignSelf: 'center',
    fontSize: 20,
  },
  subtitleText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    color: '#000000',
  },
  searchLabel: {
    color: '#4A4A4A',
    opacity: '0.5',
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '14px',
  },
  search: {
    height: '50px',
    minWidth: '300px',
    border: '1px solid #E5ECF0',
    boxSizing: 'border-box',
    borderRadius: '5px',
    background: '#FFFFFF',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: '25ch',
  },
  refreshGrid: {
    display: 'flex',
  },
  refreshParent: {
    background: '#FFFFFF',
    opacity: '0.5',
    borderRadius: '5px',
    padding: theme.spacing(0.5),
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 'auto',
  },
  refresh: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    color: '#4A4A4A',
  },
  searchIcon: {
    color: '#195880',
  },
  chip: {
    background: '#FFFFFF',
  },
  totalAgencies: {
    fontWeight: 500,
    color: '#4A4A4A',
  },
  divider: {
    height: 30,
    margin: `0 ${theme.spacing(1.25)}px`,
  },
  viewAll: {
    backgroundColor: '#E1F0FF',
    color: '#4C94EC',
    fontWeight: 600,
    textTransform: 'none',
    width: 100,
    marginRight: theme.spacing(1),
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export function TitleDashboard(props) {
  const classes = useStyles();
  const { titleText, goToAgencyAnalytics } = props;
  const Icon = props.icon;
  const tenantDetails = useTenantDetails();
  const { success, fetching, error, data } = tenantDetails;
  let refreshDate;
  let refreshDateInWords;
  if (success && data.lastRefreshDate) {
    const splitDate = data.lastRefreshDate.split('/');
    const stringDate = splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0];
    refreshDate = format(parseISO(stringDate), 'MM/dd/yyyy');

    refreshDateInWords = formatDistanceToNow(new Date(refreshDate), {
      addSuffix: true,
    });
  }

  let agencyCount;
  if (data) {
    ({ agencyCount } = data);
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={2} className={classes.left}>
          {Icon && <Icon className={classes.icon}></Icon>}
          <Typography
            className={classes.titleText}
            variant='subtitle1'
            gutterBottom
          >
            {titleText}
          </Typography>
        </Grid>
        <Grid item xs={10} className={classes.right}>
          {fetching && <Skeleton variant='rect' height={19} width={123} />}
          {agencyCount && (
            <Typography component='span' className={classes.totalAgencies}>
              Total Agencies : {agencyCount}
            </Typography>
          )}
          {agencyCount && (
            <Divider
              orientation='vertical'
              className={classes.divider}
            ></Divider>
          )}
          <Button
            className={classes.viewAll}
            disableElevation
            variant='contained'
            color='primary'
            size='medium'
            onClick={goToAgencyAnalytics}
          >
            View All
          </Button>
          {success && refreshDate && !error && (
            <LightTooltip
              title={'Data was last refreshed on ' + refreshDate}
              arrow
            >
              <Chip
                className={classes.chip}
                label={refreshDateInWords}
                icon={<UpdateIcon color='primary' />}
              />
            </LightTooltip>
          )}
          {fetching && <TitleSkeleton />}
        </Grid>
      </Grid>
    </div>
  );
}

TitleDashboard.propTypes = {
  titleText: PropTypes.string.isRequired,
  Icon: PropTypes.func,
  goToAgencyAnalytics: PropTypes.func.isRequired,
};
