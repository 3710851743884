import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { getLabelForRole } from './../UserContextProvider/roles';

export function CompleteProfileSameRoleConfirmation(props) {
  const { open, onClose, onConfirm, userData } = props;
  let userType;
  if (userData) {
    ({ userType } = userData);
  }
  const confirm = () => {
    onConfirm(userData);
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Account Details</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Your account will have the following details. This action is not
          reversible.
        </DialogContentText>
        <List component='div' role='list'>
          <ListItem>
            <ListItemText
              primary='Role'
              secondary={getLabelForRole(userType)}
            />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={confirm} color='primary'>
          Confirm
        </Button>
        <Button onClick={onClose} color='primary'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CompleteProfileSameRoleConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    userType: PropTypes.string.isRequired,
  }),
};
