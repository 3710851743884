import customTooltip from './customTooltip';

const columnDefs = [
  {
    field: 'groupName',
    headerName: 'Group Name',
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset'],
    },
    rowGroup: true,
    enableRowGroup: true,
    hide: true,
  },
  {
    field: 'key',
    headerName: 'Key',
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset'],
    },
    width: 120,
  },
  {
    field: 'summary',
    headerName: 'Summary',
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset'],
    },
    tooltipField: 'summary',
    tooltipComponent: customTooltip,
    minWidth: 280,
  },
  {
    field: 'status',
    headerName: 'Status',
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset'],
    },
    enableRowGroup: true,
    width: 150,
  },
  {
    field: 'agency',
    headerName: 'Agency',
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset'],
    },
    tooltipField: 'agency',
    tooltipComponent: customTooltip,
    enableRowGroup: true,
    minWidth: 70,
  },
  {
    field: 'ams',
    headerName: 'AMS',
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset'],
    },
    enableRowGroup: true,
    width: 150,
  },
  {
    field: 'completeOn',
    headerName: 'Complete On',
    filter: 'agDateColumnFilter',
    filterParams: {
      buttons: ['reset'],
    },
    enableRowGroup: true,
    minWidth: 50,
  },
];

export default columnDefs;
