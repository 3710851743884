import React from 'react';
import {
  OverallBusinessTableImgIcon,
  SentimeterIcon,
  OverallPerformanceIcon,
  NewbusinessIcon,
} from '../Icons/Index';

const sentimeterTitle = (
  <span>
    SentiMeter<sup style={{ fontSize: 16 }}>&reg;</sup> Score
  </span>
);

const basePath = `/api/metrics/v1`;

const widgetProperties = [
  {
    imgPath: OverallBusinessTableImgIcon,
    title: 'Overall Business',
    apiPathGraph: `${basePath}/overallBusiness`,
    apiPathTable: `${basePath}/overallBusinessTable`,
  },
  {
    imgPath: SentimeterIcon,
    title: sentimeterTitle,
    apiPathGraph: `${basePath}/sentimeterScore`,
    apiPathTable: `${basePath}/sentimeterScoreTable`,
  },
  {
    imgPath: OverallPerformanceIcon,
    title: 'Overall Performance',
    apiPathGraph: `${basePath}/overallPerformance`,
    apiPathTable: `${basePath}/overallPerformanceTable`,
  },
  {
    imgPath: NewbusinessIcon,
    title: 'New and Remarketed Policies',
    apiPathGraph: `${basePath}/newBusiness`,
    apiPathTable: `${basePath}/newBusinessTable`,
  },
];

export { widgetProperties };
