import React, { useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import componentArray from './TabsMapping';
import { UserContext } from '../UserContext';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #D1DEE6',
  },
  indicator: {
    backgroundColor: '#2D6089',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: 500,
    fontSize: 18,
    fontColor: '#4A4A4A',
    marginRight: theme.spacing(4),
    fontFamily: ['Barlow'].join(','),
    '&$selected': {
      color: '#2D6089',
      fontWeight: 700,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
}));

export default function CustomizedTabs(props) {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const tenantApi = user.getTenantApi();
  const { handleChange, index } = props;
  const tabsList = [];
  componentArray.forEach((item, index) => {
    const { key } = item;
    if (!tenantApi.isFunctionalityHidden(key)) {
      tabsList.push(<AntTab label={key} key={index} />);
    }
  });
  return (
    <div className={classes.root}>
      <AntTabs value={index} onChange={handleChange} aria-label="ant example">
        {tabsList}
      </AntTabs>
    </div>
  );
}
