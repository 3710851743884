import React, { useContext, useState } from 'react';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { UserContext } from './../UserContext';
import {
  DashboardIcon,
  AnalyticsIcon,
  UserManagementIcon,
  TrendsIcon,
  UsageAnalyticsIcon,
} from '../Header/Icons';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import componentArray from '../UsageAnalytics/TabsMapping';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 190,
    backgroundColor: theme.palette.background.paper,
    marginLeft: 10,
    marginRight: 10,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  icon: {
    minWidth: 30,
  },
  expandedMenuItem: {
    background: '#DEE4E8',
    borderRadius: '0 0 5px 5px',
  },
  selected: {
    '&.Mui-selected': {
      background: '#DEE4E8',
      borderRadius: 5,
    },
  },
  selectedText: {
    '& > .MuiTypography-root': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));

const DASHBOARD_VIEW_KEY = 'Dashboard';
const DASHBOARD_PATH = '/home/dashboard';
const AGENCY_ANALYTICS_VIEW_KEY = 'Agency Analytics';
const AGENCY_ANALYTICS_PATH = '/home/agencyanalytics';
const USERMANAGEMENT_VIEW_KEY = 'User Management';
const USER_MANAGEMENT_PATH = '/home/users';
const TRENDS_VIEW_KEY = 'Trends';
const TRENDS_PATH = '/home/trends';
const USAGE_ANALYTICS_VIEW_KEY = 'Usage Analytics';
const USAGE_ANALYTICS_PATH = '/home/usageanalytics';

export function NestedList(props) {
  const { onClose: handleClose } = props;
  const classes = useStyles();
  const history = useHistory();
  const { pathname: pathName } = useLocation();
  const { user } = useContext(UserContext);
  const hasUserManagement = user.isUserManagementAllowed();
  const [viewsOpen, setViewsOpen] = useState(false);
  const tenantApi = user.getTenantApi();

  const handleLink = (link) => {
    history.push(link);
    handleClose();
  };

  const handleViewsClick = () => {
    setViewsOpen(!viewsOpen);
  };

  let usageAnalyticsList = [];
  componentArray.forEach((item, index) => {
    const { key, icon } = item;
    if (!tenantApi.isFunctionalityHidden(key)) {
      usageAnalyticsList.push(
        <ListItem
          button
          className={classes.nested}
          onClick={() => handleLink(`${USAGE_ANALYTICS_PATH}?index=${index}`)}
          selected={matchPath(
            pathName,
            `${USAGE_ANALYTICS_PATH}?index=${index}`
          )}
          classes={{ root: classes.selected }}
        >
          <ListItemIcon classes={{ root: classes.icon }}>{icon}</ListItemIcon>
          <ListItemText
            primary={key}
            className={clsx({
              [classes.selectedText]: matchPath(
                pathName,
                `${USAGE_ANALYTICS_PATH}?index=${index}`
              ),
            })}
          />
        </ListItem>
      );
    }
  });

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
        ></ListSubheader>
      }
      className={classes.root}
    >
      {!tenantApi.isFunctionalityHidden(DASHBOARD_VIEW_KEY) && (
        <ListItem
          button
          onClick={() => handleLink(DASHBOARD_PATH)}
          selected={matchPath(pathName, DASHBOARD_PATH)}
          classes={{ root: classes.selected }}
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText
            primary={DASHBOARD_VIEW_KEY}
            className={clsx({
              [classes.selectedText]: matchPath(pathName, DASHBOARD_PATH),
            })}
          />
        </ListItem>
      )}
      {!tenantApi.isFunctionalityHidden(AGENCY_ANALYTICS_VIEW_KEY) && (
        <ListItem
          button
          onClick={() => handleLink(AGENCY_ANALYTICS_PATH)}
          selected={matchPath(pathName, AGENCY_ANALYTICS_PATH)}
          classes={{ root: classes.selected }}
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            <AnalyticsIcon />
          </ListItemIcon>
          <ListItemText
            primary={AGENCY_ANALYTICS_VIEW_KEY}
            className={clsx({
              [classes.selectedText]: matchPath(
                pathName,
                AGENCY_ANALYTICS_PATH
              ),
            })}
          />
        </ListItem>
      )}
      {!tenantApi.isFunctionalityHidden(TRENDS_VIEW_KEY) && (
        <ListItem
          button
          onClick={() => handleLink(TRENDS_PATH)}
          selected={matchPath(pathName, TRENDS_PATH)}
          classes={{ root: classes.selected }}
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            <TrendsIcon />
          </ListItemIcon>
          <ListItemText
            primary={TRENDS_VIEW_KEY}
            className={clsx({
              [classes.selectedText]: matchPath(pathName, TRENDS_PATH),
            })}
          />
        </ListItem>
      )}
      {hasUserManagement &&
        !tenantApi.isFunctionalityHidden(USERMANAGEMENT_VIEW_KEY) && (
          <ListItem
            button
            onClick={() => handleLink(USER_MANAGEMENT_PATH)}
            selected={matchPath(pathName, USER_MANAGEMENT_PATH)}
            classes={{ root: classes.selected }}
          >
            <ListItemIcon classes={{ root: classes.icon }}>
              <UserManagementIcon />
            </ListItemIcon>
            <ListItemText
              primary={USERMANAGEMENT_VIEW_KEY}
              className={clsx({
                [classes.selectedText]: matchPath(
                  pathName,
                  USER_MANAGEMENT_PATH
                ),
              })}
            />
          </ListItem>
        )}
      {!tenantApi.isFunctionalityHidden(USAGE_ANALYTICS_VIEW_KEY) && (
        <div>
          <ListItem
            button
            onClick={handleViewsClick}
            selected={viewsOpen}
            classes={{ root: classes.selectedCollapse }}
          >
            <ListItemIcon classes={{ root: classes.icon }}>
              <UsageAnalyticsIcon />
            </ListItemIcon>
            <ListItemText
              primary={USAGE_ANALYTICS_VIEW_KEY}
              className={clsx({})}
            />
            {viewsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse
            in={viewsOpen}
            timeout="auto"
            unmountOnExit
            className={classes.expandedMenuItem}
          >
            <List component="div" disablePadding>
              {usageAnalyticsList}
            </List>
          </Collapse>
        </div>
      )}
    </List>
  );
}

NestedList.propTypes = {
  onClose: PropTypes.func.isRequired,
};
