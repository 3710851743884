const roles = {
  SUPER_USER: {
    key: 'super-user',
    label: 'Super User',
    userManagement: true,
    settings: true,
    enabled: true,
    linkable: false,
    linkTarget: false,
    updateSource: false,
    updateTarget: false,
    vizAccess: true,
    assignable: false,
    activityAccess: true,
    billing: true,
  },
  ADMIN: {
    key: 'admin',
    label: 'Administrator',
    userManagement: true,
    settings: true,
    enabled: true,
    linkable: true,
    linkTarget: false,
    updateSource: false,
    updateTarget: false,
    vizAccess: true,
    assignable: true,
    activityAccess: false,
    billing: true,
  },
  STANDARD: {
    key: 'standard',
    label: 'Standard',
    userManagement: false,
    settings: true,
    enabled: true,
    linkable: false,
    linkTarget: true,
    updateSource: false,
    updateTarget: true,
    vizAccess: true,
    assignable: true,
    billing: true,
  },
};

const getEnabledRolesAsList = () => {
  const rolesList = [];
  Object.keys(roles).forEach((roleInternalKey) => {
    const roleDetails = roles[roleInternalKey];
    const { enabled } = roleDetails;
    if (enabled) {
      rolesList.push(roleDetails);
    }
  });
  return rolesList;
};

const getRoleDetails = (roleKey) => {
  return getEnabledRolesAsList().find(
    (roleDetails) => roleDetails.key === roleKey
  );
};

const isPersonalDiemEnabledForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.personalDiem;
};

const isUserManagementAllowedForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.userManagement;
};

const isLinkingAllowedForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.linkable;
};

const isSettingChangeAllowedForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.settings;
};

const isVizAccessAllowedForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.vizAccess;
};

const isActivityAccessAllowedForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.activityAccess;
};

const getLabelForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.label;
};

const getRoleLabelMapping = () => {
  const mapping = {};
  getEnabledRolesAsList().forEach((roleDetails) => {
    const { key, label } = roleDetails;
    mapping[key] = label;
  });
  return mapping;
};

const getAssignableRoleLabelMapping = () => {
  const mapping = {};
  getEnabledRolesAsList()
    .filter((roleDetails) => roleDetails.assignable)
    .forEach((roleDetails) => {
      const { key, label } = roleDetails;
      mapping[key] = label;
    });
  return mapping;
};

const getRoleKeys = () => {
  return getEnabledRolesAsList().map((roleDetails) => roleDetails.key);
};

const getAssignableRoleKeys = () => {
  return getEnabledRolesAsList()
    .filter((roleDetails) => roleDetails.assignable)
    .map((roleDetails) => roleDetails.key);
};

const getUpdateSourceRoleKeys = () => {
  return getEnabledRolesAsList()
    .filter((roleDetails) => roleDetails.updateSource)
    .map((roleDetails) => roleDetails.key);
};

const getUpdateTargetRoleKeys = () => {
  return getEnabledRolesAsList()
    .filter((roleDetails) => roleDetails.updateTarget)
    .map((roleDetails) => roleDetails.key);
};

const getLinkTargetRoleKeys = () => {
  return getEnabledRolesAsList()
    .filter((roleDetails) => roleDetails.assignable)
    .filter((roleDetails) => roleDetails.linkTarget)
    .map((roleDetails) => roleDetails.key);
};

const isRoleAssignable = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.assignable;
};

const isBillingAllowedForRole = (roleKey) => {
  const roleDetails = getRoleDetails(roleKey);
  return roleDetails && roleDetails.billing;
};

module.exports = {
  getRoleDetails,
  isPersonalDiemEnabledForRole,
  isUserManagementAllowedForRole,
  isLinkingAllowedForRole,
  isSettingChangeAllowedForRole,
  isRoleAssignable,
  getLabelForRole,
  getRoleLabelMapping,
  getAssignableRoleLabelMapping,
  getRoleKeys,
  getLinkTargetRoleKeys,
  isVizAccessAllowedForRole,
  isActivityAccessAllowedForRole,
  getAssignableRoleKeys,
  getUpdateSourceRoleKeys,
  getUpdateTargetRoleKeys,
  isBillingAllowedForRole,
};
