import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

function TrendsError() {
  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      {'Cannot create view'}
    </Alert>
  );
}

export default TrendsError;
