import React from 'react';
import PropTypes from 'prop-types';
import { TableauMarkSelectionView } from '../TableauView';
import { useViewDetails } from '../useViewDetails';
import { DashboardSkeleton } from './DashboardSkeleton';
import { DashboardError } from './DashboardError';
import { getAgencyNamesFromMarks } from '../TableauView/getAgencyNameFromMarks';
import { useTenantDetails } from '../Header/useTenantDetails';
import { tenantLabels } from '../TableauView/tenantLabels';

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

const DashboardWidget = (props) => {
  const { apiPath, goToAgencyDetails } = props;
  const details = useViewDetails(apiPath);
  const { fetching, success, error, data } = details;
  const { data: tenantDetails } = useTenantDetails();

  if (data && tenantDetails) {
    const { filter } = data;
    const { carrierLabel } = tenantDetails;
    let modifiedFilter;
    const parsedCarrierLabel = carrierLabel.replace(
      /[-/\\^$,*+?.()|[\]{}]/g,
      '\\$&'
    );

    if (typeof filter === 'string') {
      if (filter === '') {
        modifiedFilter = `${tenantLabels.CARRIERLABEL}=${parsedCarrierLabel}`;
      } else {
        modifiedFilter = `${filter}&${tenantLabels.CARRIERLABEL}=${parsedCarrierLabel}`;
      }
    }
    data.filter = modifiedFilter;
  }

  const onSelect = async (marks, worksheetName, viz) => {
    const agencyName = getAgencyNamesFromMarks(marks)[0];
    if (agencyName) {
      goToAgencyDetails(agencyName);
    }
  };

  return (
    <>
      {fetching && <DashboardSkeleton />}
      {success && (
        <TableauMarkSelectionView
          {...data}
          {...dimensions}
          adjustHeight={true}
          onSelect={onSelect}
        />
      )}
      {error && <DashboardError />}
    </>
  );
};

DashboardWidget.propTypes = {
  apiPath: PropTypes.string.isRequired,
  goToAgencyDetails: PropTypes.func.isRequired,
};

export { DashboardWidget };
