import React, { useContext, useEffect, useState } from 'react';
import { Title } from '../Header';
import { UsageAnalyticsIcon } from '../Header/Icons';
import {
  Route,
  useRouteMatch,
  Switch,
  useLocation,
  Redirect,
} from 'react-router-dom';
import CustomizedTabs from './Tabs';
import componentArray from './TabsMapping';
import { UserContext } from '../UserContext';

const USAGE_ANALYTICS_VIEW_KEY = 'Usage Analytics';

const UsageAnalytics = (props) => {
  const match = useRouteMatch();
  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const index = query.get('index');
  let selected;
  if (index !== null) {
    selected = parseInt(index);
  } else {
    selected = 0;
  }
  const [componentIndex, setComponentIndex] = useState(selected);

  useEffect(() => {
    setComponentIndex(selected);
  }, [selected]);

  const handleChange = (event, newValue) => {
    setComponentIndex(newValue);
  };

  const { user } = useContext(UserContext);
  const tenantApi = user.getTenantApi();
  const isAgencyAnalyticsHidden = tenantApi.isFunctionalityHidden(
    USAGE_ANALYTICS_VIEW_KEY
  );
  if (isAgencyAnalyticsHidden) {
    const redirectionPath = tenantApi.getRedirectionPath();
    return <Redirect to={redirectionPath} />;
  }
  const { component } = componentArray[componentIndex];

  return (
    <>
      <Switch>
        <Route path={`${match.path}`}>
          <Title
            icon={UsageAnalyticsIcon}
            titleText={USAGE_ANALYTICS_VIEW_KEY}
          />
          <CustomizedTabs
            index={componentIndex}
            handleChange={handleChange}
          ></CustomizedTabs>
          {component}
        </Route>
      </Switch>
    </>
  );
};

export default UsageAnalytics;
