import React from 'react';
import { ReactComponent as OverallBusinessTableImg } from '../../assets/overalbusinesstable.svg';
import { ReactComponent as Sentimeter } from '../../assets/sentimeterscore.svg';
import { ReactComponent as OverallPerformance } from '../../assets/overallperformance.svg';
import { ReactComponent as Newbusiness } from '../../assets/newbusiness.svg';
import { ReactComponent as AgencyDetails } from '../../assets/agencydetails.svg';

const OverallBusinessTableImgIcon = () => <OverallBusinessTableImg />;

const SentimeterIcon = () => <Sentimeter />;

const OverallPerformanceIcon = () => <OverallPerformance />;

const NewbusinessIcon = () => <Newbusiness />;

const AgencyDetailsIcon = () => <AgencyDetails />;

export {
  OverallBusinessTableImgIcon,
  SentimeterIcon,
  OverallPerformanceIcon,
  NewbusinessIcon,
  AgencyDetailsIcon,
};
