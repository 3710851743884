import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

function AgencyAnalyticsError() {
  return (
    <Alert severity='error'>
      <AlertTitle>Error</AlertTitle>
      {'Cannot create view'}
    </Alert>
  );
}

export { AgencyAnalyticsError };
