import React from 'react';
import { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { Dashboard } from './../Dashboard';
import { UserContext } from './../UserContext';
import { UserManagement } from '../UserManagement/UserManagement';
import { AgencyAnalytics } from '../AgencyAnalytics/AgencyAnalytics';
import Trends from '../Trends/Trends';
import UsageAnalytics from '../UsageAnalytics/UsageAnalytics';

const useStyles = makeStyles((theme) => ({
  screenContainer: {
    paddingBottom: theme.spacing(6),
  },
}));

export function Home() {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const isVizAccessAllowed = user.isVizAccessAllowed();
  let match = useRouteMatch();
  const firstView = 'dashboard';

  return (
    <Switch>
      {isVizAccessAllowed && firstView && (
        <Route exact path={match.path}>
          <Redirect to={`${match.path}/${firstView}`} />
        </Route>
      )}
      <Route path={match.path}>
        <Container className={classes.screenContainer} maxWidth="xl">
          {isVizAccessAllowed && (
            <>
              <Route path={`${match.path}/dashboard`}>
                <Dashboard />
              </Route>
              <Route path={`${match.path}/users`}>
                <UserManagement />
              </Route>
              <Route path={`${match.path}/agencyanalytics`}>
                <AgencyAnalytics />
              </Route>
              <Route path={`${match.path}/trends`}>
                <Trends />
              </Route>
              <Route path={`${match.path}/usageanalytics`}>
                <UsageAnalytics />
              </Route>
            </>
          )}
        </Container>
      </Route>
    </Switch>
  );
}
