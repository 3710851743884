import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from '../Header';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router';
import { useViewDetails } from '../useViewDetails';
import { AgencyDetailsSkeleton } from './AgencyDetailsSkeleton';
import { AgencyDetailsError } from './AgencyDetailsError';
import { TableauMarkSelectionView } from '../TableauView';
import { useParams } from 'react-router-dom';
import { useTenantDetails } from '../Header/useTenantDetails';
import { tenantLabels } from '../TableauView/tenantLabels';

const useStyles = makeStyles((theme) => ({
  leftArrow: {
    paddingTop: theme.spacing(3),
  },
}));

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

const agencyKey = 'AgencyNames';

const AgencyDetails = (props) => {
  const classes = useStyles();
  const { backPath } = props;
  const history = useHistory();
  const { agencyName } = useParams();
  const apiPath = '/api/metrics/v1/agencyAnalytics';
  const details = useViewDetails(apiPath);
  const { fetching, success, error, data } = details;
  const iconText = agencyName.slice(0, 3).toUpperCase();
  const { data: tenantDetails } = useTenantDetails();

  if (data && agencyName && tenantDetails) {
    const { filter } = data;
    const { carrierLabel } = tenantDetails;
    let modifiedFilter;
    const parsedAgencyName = agencyName.replace(
      /[-/\\^$,*+?.()|[\]{}]/g,
      '\\$&'
    );
    const parsedCarrierLabel = carrierLabel.replace(
      /[-/\\^$,*+?.()|[\]{}]/g,
      '\\$&'
    );

    if (typeof filter === 'string') {
      if (filter === '') {
        modifiedFilter = `${agencyKey}=${parsedAgencyName}&${tenantLabels.CARRIERLABEL}=${parsedCarrierLabel}`;
      } else {
        modifiedFilter = `${filter}&${agencyKey}=${parsedAgencyName}&${tenantLabels.CARRIERLABEL}=${parsedCarrierLabel}`;
      }
    }
    data.filter = modifiedFilter;
  }

  const handleBack = () => {
    history.push(backPath);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <IconButton
            edge="start"
            aria-label="close"
            onClick={handleBack}
            className={classes.leftArrow}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs>
          <Title titleText={agencyName} iconText={iconText} />
        </Grid>
      </Grid>

      {fetching && <AgencyDetailsSkeleton />}
      {success && (
        <TableauMarkSelectionView
          {...data}
          {...dimensions}
          adjustHeight={true}
        />
      )}
      {error && <AgencyDetailsError />}
    </>
  );
};

AgencyDetails.propTypes = {
  backPath: PropTypes.string.isRequired,
};

export { AgencyDetails };
