import React from 'react';
import { getAssignableRoleLabelMapping } from './../UserContextProvider/roles';
import { getLabelForRole } from './../UserContextProvider/roles';
import { StatusDetails } from './StatusDetails';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';

const ADMIN_ROLE_KEY = 'admin';

export const getReportsToMapping = (users, initialsOnly) => {
  const reportsToMapping = {
    0: '',
  };
  users.forEach((user) => {
    const { userID, userInitials, firstName, lastName, userType } = user;
    if (userInitials) {
      let label = userInitials;
      if (firstName && lastName && userType && initialsOnly !== true) {
        label = `${userInitials} - ${firstName} ${lastName} (${getLabelForRole(
          userType
        )})`;
      }
      reportsToMapping[userID] = label;
    }
  });
  return reportsToMapping;
};

export const getColumns = (users, userEmail, userRole) => {
  const columns = [
    {
      title: 'Photo',
      editable: 'never',
      sorting: false,
      render: (userData) => <StatusDetails userData={userData} />,
    },
    {
      title: 'Type',
      field: 'userType',
      lookup: getAssignableRoleLabelMapping(),
      editComponent: (props) => {
        const {
          columnDef,
          rowData,
          onRowDataChange,
          errorState,
          ...otherProps
        } = props;
        const disabled =
          rowData.emailID === userEmail && userRole === ADMIN_ROLE_KEY;
        const { helperText, error, ...selectProps } = otherProps;
        return (
          <FormControl error={Boolean(error)}>
            <Select
              {...selectProps}
              value={props.value === undefined ? '' : props.value}
              onChange={(event) => props.onChange(event.target.value)}
              style={{
                fontSize: 13,
              }}
              SelectDisplayProps={{ 'aria-label': props.columnDef.title }}
              disabled={disabled}
            >
              {Object.keys(props.columnDef.lookup).map((key) => (
                <MenuItem key={key} value={key}>
                  {props.columnDef.lookup[key]}
                </MenuItem>
              ))}
            </Select>
            {Boolean(helperText) && (
              <FormHelperText>{helperText}</FormHelperText>
            )}
          </FormControl>
        );
      },
    },
    {
      title: 'First Name',
      field: 'firstName',
    },
    {
      title: 'Last Name',
      field: 'lastName',
    },
    {
      title: 'Email ID',
      field: 'emailID',
      editComponent: (props) => {
        const {
          columnDef,
          rowData,
          onRowDataChange,
          errorState,
          ...textFieldProps
        } = props;
        const disabled = rowData.hasBasicDetails;
        return (
          <TextField
            {...textFieldProps}
            fullWidth
            disabled={disabled}
            style={props.columnDef.type === 'numeric' ? { float: 'right' } : {}}
            type={props.columnDef.type === 'numeric' ? 'number' : 'text'}
            placeholder={
              props.columnDef.editPlaceholder || props.columnDef.title
            }
            value={props.value === undefined ? '' : props.value}
            onChange={(event) =>
              props.onChange(
                props.columnDef.type === 'numeric'
                  ? event.target.valueAsNumber
                  : event.target.value
              )
            }
            InputProps={{
              style: {
                fontSize: 13,
              },
              inputProps: {
                'aria-label': props.columnDef.title,
              },
            }}
          />
        );
      },
    },
  ];
  return columns;
};
