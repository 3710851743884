import React from 'react';
import { useContext } from 'react';
import logo from './../../assets/Harvey.svg';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTenantDetails } from './useTenantDetails';
import { UserContext } from './../UserContext';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
  },
  logoAlt: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  tenantText: {
    marginLeft: theme.spacing(2),
  },
}));

export function AppLogo() {
  const { user } = useContext(UserContext);
  const tenant = user.getTenant();
  const classes = useStyles();
  const { fetching, error, success, data: tenantDetails } = useTenantDetails();
  let agencyLogo;
  if (success) {
    ({ agencyLogo } = tenantDetails);
  }
  const showAgencyLogo = success && agencyLogo;
  const showDefaultLogo = (success && !agencyLogo) || error;
  return (
    <div className={classes.root}>
      {showAgencyLogo && (
        <img className={classes.logo} src={agencyLogo} alt={tenant} />
      )}
      {fetching && <Skeleton variant='rect' height={41} width={160} />}
      {showDefaultLogo && (
        <div className={classes.logoAlt}>
          <img src={logo} alt='Harvey' />
        </div>
      )}
    </div>
  );
}
