import React from 'react';
import UsageAnalyticsSkeleton from './UsageAnalyticsSkeleton';
import UsageAnalyticsError from './UsageAnalyticsError';
import { useViewDetails } from '../useViewDetails';
import { TableauMarkSelectionView } from '../TableauView';

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

export function UsageData(props) {
  const usageDataPath = `/api/metrics/v1/usageData`;
  const usageData = useViewDetails(usageDataPath);
  const { fetching, success, error, data } = usageData;

  return (
    <div>
      {fetching && <UsageAnalyticsSkeleton />}
      {success && (
        <TableauMarkSelectionView
          {...data}
          {...dimensions}
          adjustHeight={true}
        />
      )}
      {error && <UsageAnalyticsError />}
    </div>
  );
}
