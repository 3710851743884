import React from 'react';

const customTooltip = (params) => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        border: '1px solid #d1dee6',
        padding: '0 5px 0 5px',
      }}
    >
      <p>{params.value}</p>
    </div>
  );
};

export default customTooltip;
