import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { UserDetail } from './UserDetail';
import Divider from '@material-ui/core/Divider';
import { getLabelForRole } from './../UserContextProvider/roles';

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    padding: theme.spacing(3),
  },
}));

export function UserDetails(props) {
  const classes = useStyles();
  const { userDetails } = props;
  const { firstName, lastName, emailID, userInitials, userType } = userDetails;
  const role = getLabelForRole(userType);
  return (
    <div>
      <div className={classes.detailsContainer}>
        <UserDetail detailKey='Name' detailValue={`${firstName} ${lastName}`} />
        <Divider />
        <UserDetail detailKey='Email' detailValue={emailID} />
        <Divider />
        {userInitials && (
          <>
            <UserDetail detailKey='User Id' detailValue={userInitials} />
            <Divider />
          </>
        )}
        <UserDetail detailKey='Role' detailValue={role} />
      </div>
    </div>
  );
}

UserDetails.props = {
  userDetails: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    emailID: PropTypes.string.isRequired,
    userInitials: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired,
  }),
};
