import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  summary: {
    height: 61,
    borderBottom: '1px solid #D1DEE6',
    minHeight: 'unset',
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),

    marginLeft: 15,
    color: '#4A4A4A',
    fontWeight: theme.typography.fontWeightBold,
  },
  titleimg: {
    width: 40,
  },
  content: {
    alignItems: 'center',
  },
  expandIcon: {
    padding: 1,
  },
  expandText: {
    marginLeft: 'auto',
    marginBottom: 4,
  },
}));

const WidgetAccordion = (props) => {
  const classes = useStyles();
  const { ImgPath, title, setExpanded } = props;
  let { expanded } = props;
  if (expanded === undefined) {
    expanded = 'Overall Business';
  }
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <>
      <Accordion expanded={expanded === title} onChange={handleChange(title)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          classes={{
            content: classes.content,
            expandIcon: classes.expandIcon,
            root: classes.summary,
          }}
        >
          <ImgPath width='40px' />
          <Typography className={classes.heading}>{title}</Typography>
          <div className={classes.expandText}>
            {expanded === title && (
              <Typography variant='caption'>Hide</Typography>
            )}
            {expanded !== title && (
              <Typography variant='caption'>Show</Typography>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>{props.children}</AccordionDetails>
      </Accordion>
    </>
  );
};

WidgetAccordion.propTypes = {
  ImgPath: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  expanded: PropTypes.string,
  setExpanded: PropTypes.func.isRequired,
};

export { WidgetAccordion };
