import functionalities from '../Functionalities/Functionalities';
import { isCovQuotaForPlan } from './plans';

export class Tenant {
  constructor(tenantDetails) {
    let details;
    if (typeof tenantDetails !== 'object') {
      details = {};
    } else {
      details = { ...tenantDetails };
    }
    this.tenantDetails = details;
    this.getContextIdentifier = this.getContextIdentifier.bind(this);
    this.getAgencyName = this.getAgencyName.bind(this);
    this.getAgencyLogo = this.getAgencyLogo.bind(this);
    this.getPlanType = this.getPlanType.bind(this);
    this.getDisplayConfig = this.getDisplayConfig.bind(this);
  }

  getContextIdentifier() {
    const { covContextIdentifier } = this.tenantDetails;
    return covContextIdentifier;
  }

  getAgencyName() {
    const { agencyName } = this.tenantDetails;
    return agencyName;
  }

  getAgencyLogo() {
    const { agencyLogo } = this.tenantDetails;
    return agencyLogo;
  }

  getPlanType() {
    try {
      const billingStatus = JSON.parse(this.tenantDetails.billingStatus);
      return billingStatus.plan_type;
    } catch (e) {
      return undefined;
    }
  }

  hasCovQuota() {
    const planType = this.getPlanType();
    if (planType) {
      return isCovQuotaForPlan(planType);
    }
    return false;
  }

  getDisplayConfig() {
    let config;
    try {
      const { displayConfig } = this.tenantDetails;
      config = JSON.parse(displayConfig);
    } catch (e) {
      config = undefined;
    }
    return config;
  }

  isFunctionalityHidden(value) {
    let isHidden = false;
    const displayConfig = this.getDisplayConfig();
    if (displayConfig !== undefined) {
      const { views } = displayConfig;
      views.forEach((view) => {
        const { key, hide } = view;
        if (key === value) {
          isHidden = hide;
        }
      });
    }
    return isHidden;
  }

  getRedirectionPath = () => {
    let response = '/home';
    for (let i = 0; i < functionalities.length; i++) {
      const functionality = functionalities[i];
      const { name, url } = functionality;
      const isFunctionalityHidden = this.isFunctionalityHidden(name);
      if (!isFunctionalityHidden) {
        response = url;
        break;
      }
    }
    return response;
  };
}
