import { getAllMarkValues } from './marks';

const agencyNameKeys = [
  'Apply button',
  'Apply Button',
  'Apply_Button',
  'apply_button',
  'ApplyButton',
  'applybutton',
  'Applybutton',
  'applyButton',
];

export const getApplyButtonFromMarks = (marks) => {
  return getAllMarkValues(marks, agencyNameKeys);
};
