import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { AgencyDetailsIcon } from '../Icons/Index';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  iconGrid: {
    alignSelf: 'center',
    marginBottom: 7,
  },
  iconTextGrid: {
    position: 'relative',
  },
  icon: {
    color: '#2D6089',
  },
  titleText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 20,
    color: '#4A4A4A',
    marginBottom: 0,
  },
  verticalLine: {
    Stroke: 'Solid',
  },
  count: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#8D72CA',
    alignSelf: 'center',
    fontSize: 20,
  },
  subtitleText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    color: '#000000',
  },
  alignCenterText: {
    alignItems: 'center',
  },
  divider: {
    height: 30,
    margin: `0 ${theme.spacing(1.25)}px`,
  },
  marginBottom: {
    marginBottom: theme.spacing(1.25),
  },
  updateButton: {
    background: '#19588036',
    color: '#195880',
    textTransform: 'inherit',
    height: 30,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: 4,
  },
  iconText: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 10,
    position: 'absolute',
    top: 9,
    left: 7,
  },
}));

export function Title(props) {
  const classes = useStyles();
  const { titleText, count, subtitleText, left, iconText } = props;
  const Icon = props.icon;
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {Icon && (
          <Grid item className={classes.iconGrid}>
            <Icon className={classes.icon}></Icon>
          </Grid>
        )}
        {iconText && (
          <Grid item className={clsx(classes.iconGrid, classes.iconTextGrid)}>
            <AgencyDetailsIcon className={classes.icon} />
            <Typography
              align='center'
              color='primary'
              className={classes.iconText}
            >
              {iconText}
            </Typography>
          </Grid>
        )}
        <Grid className={classes.marginBottom} item sm container>
          <Grid
            container
            classes={{
              root: classes.alignCenterText,
            }}
            alignContent='center'
          >
            <Grid>
              <Typography
                className={classes.titleText}
                variant='subtitle1'
                gutterBottom
              >
                {titleText}
              </Typography>
            </Grid>
            <Grid>
              {count && (
                <Divider
                  orientation='vertical'
                  className={classes.divider}
                ></Divider>
              )}
            </Grid>
            <Grid>{count && <div className={classes.count}>{count}</div>}</Grid>
            {left !== undefined && (
              <>
                <Divider
                  orientation='vertical'
                  className={classes.divider}
                ></Divider>
                {left}
              </>
            )}
          </Grid>
          {subtitleText && (
            <Typography className={classes.subtitleText} variant='body2'>
              {subtitleText}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Divider orientation='horizontal'></Divider>
    </div>
  );
}

Title.propTypes = {
  titleText: PropTypes.string.isRequired,
  Icon: PropTypes.func,
  left: PropTypes.element,
  iconText: PropTypes.string,
};
