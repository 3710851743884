import React from 'react';
import { ReactComponent as TutorialsAndVideos } from '../../assets/icons/TutorialsAndVideos.svg';
import { ReactComponent as Home } from '../../assets/icons/Home.svg';
import { ReactComponent as Analytics } from '../../assets/icons/Analytics.svg';
import { ReactComponent as Foresight } from '../../assets/icons/Foresight.svg';
import { ReactComponent as Settings } from '../../assets/icons/Settings.svg';
import { ReactComponent as Metrics } from '../../assets/icons/Metrics.svg';
import { ReactComponent as Trends } from '../../assets/icons/Trends.svg';
import { ReactComponent as ContactSupport } from '../../assets/icons/ContactSupport.svg';
import { ReactComponent as MyProfile } from '../../assets/icons/MyProfile.svg';
import { ReactComponent as BillingsAndPayments } from '../../assets/icons/BillingsAndPayments.svg';
import { ReactComponent as ChangePassword } from '../../assets/icons/ChangePassword.svg';
import { ReactComponent as Logout } from '../../assets/icons/Logout.svg';
import { ReactComponent as Dashboard } from '../../assets/icons/Dashboard.svg';
import { ReactComponent as UserManagement } from '../../assets/icons/UserManagement.svg';
import { ReactComponent as UsageAnalytics } from '../../assets/icons/UsageAnalytics.svg';
import { ReactComponent as AgencyData } from '../../assets/icons/AgencyData.svg';
import { ReactComponent as UsageData } from '../../assets/icons/UsageData.svg';
import { ReactComponent as KPI } from '../../assets/icons/KPI.svg';
import { ReactComponent as SupportTickets } from '../../assets/icons/SupportTickets.svg';
import { ReactComponent as Download } from '../../assets/icons/Download.svg';
import { ReactComponent as Options } from '../../assets/icons/Options.svg';

const TutorialsAndVideosIcon = () => {
  return <TutorialsAndVideos />;
};

const ContactSupportIcon = () => {
  return <ContactSupport />;
};

const DashboardIcon = () => {
  return <Dashboard />;
};
const HomeIcon = () => {
  return <Home />;
};

const AnalyticsIcon = () => {
  return <Analytics />;
};
const ForesightIcon = () => {
  return <Foresight />;
};

const MetricsIcon = () => {
  return <Metrics />;
};

const TrendsIcon = () => {
  return <Trends />;
};

const SettingsIcon = () => {
  return <Settings />;
};

const MyProfileIcon = () => {
  return <MyProfile />;
};

const BillingsAndPaymentsIcon = () => {
  return <BillingsAndPayments />;
};

const ChangePasswordIcon = () => {
  return <ChangePassword />;
};

const LogoutIcon = () => {
  return <Logout />;
};

const UserManagementIcon = () => {
  return <UserManagement />;
};

const UsageAnalyticsIcon = () => {
  return <UsageAnalytics />;
};

const AgencyDataIcon = () => {
  return <AgencyData />;
};

const UsageDataIcon = () => {
  return <UsageData />;
};

const KPIIcon = () => {
  return <KPI />;
};

const SupportTicketsIcon = () => {
  return <SupportTickets />;
};

const DownloadIcon = () => {
  return <Download />;
};

const OptionsIcon = () => {
  return <Options />;
};

export {
  TutorialsAndVideosIcon,
  HomeIcon,
  AnalyticsIcon,
  ForesightIcon,
  SettingsIcon,
  MetricsIcon,
  TrendsIcon,
  DashboardIcon,
  ContactSupportIcon,
  MyProfileIcon,
  BillingsAndPaymentsIcon,
  ChangePasswordIcon,
  LogoutIcon,
  UserManagementIcon,
  UsageAnalyticsIcon,
  AgencyDataIcon,
  UsageDataIcon,
  KPIIcon,
  SupportTicketsIcon,
  DownloadIcon,
  OptionsIcon,
};
