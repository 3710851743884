import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { UserContextProvider } from './../UserContextProvider';
import { Home } from './../Home';
import { Header } from './../Header';
import { Settings } from './../Settings';
import { LoginPrompt } from './../LoginPrompt';
import { GoogleAnalytics } from './../GoogleAnalytics';
import { Hotjar } from './../Hotjar';
import { UserGuiding } from './../UserGuiding';

export function Content() {
  return (
    <Switch>
      <Route exact path='/'>
        <Redirect to='/login/prompt' />
      </Route>
      <Route path='/login'>
        <LoginPrompt />
      </Route>
      <Route path='/home'>
        <UserContextProvider>
          <GoogleAnalytics>
            <Hotjar>
              <UserGuiding />
              <Switch>
                <Route path='/home/settings'>
                  <Settings />
                </Route>
                <Route path='/home'>
                  <Header />
                  <Home />
                </Route>
              </Switch>
            </Hotjar>
          </GoogleAnalytics>
        </UserContextProvider>
      </Route>
    </Switch>
  );
}
