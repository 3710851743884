import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { MenuButton } from './MenuButton';
import { AppLogo } from './AppLogo';
import { UserInfo } from './UserInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridRoot: {
    flexGrow: 1,
  },
  appBar: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create('width'),
  },
}));

export function Header() {
  const classes = useStyles();
  const gridPoints = {
    md: 6,
    lg: 6,
    xl: 6,
  };
  return (
    <div className={classes.root}>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar>
          <div className={classes.gridRoot}>
            <Grid container spacing={2} justify="space-between">
              <Grid
                item
                md={gridPoints.md}
                lg={gridPoints.lg}
                xl={gridPoints.xl}
                spacing={1}
                justify="flex-start"
                align="center"
                container
              >
                <MenuButton />
                <AppLogo />
              </Grid>
              <Grid
                item
                md={gridPoints.md}
                lg={gridPoints.lg}
                xl={gridPoints.xl}
              >
                <UserInfo />
              </Grid>
            </Grid>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
