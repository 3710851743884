import { getAllMarkValues } from './marks';

const agencyNameKeys = [
  'Agency Name',
  'Agency_Name',
  'agency_name',
  'AgencyName',
  'agencyname',
  'Agencyname',
  'agencyName',
];

export const getAgencyNamesFromMarks = (marks) => {
  return getAllMarkValues(marks, agencyNameKeys);
};
