import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

function AgencyDetailsError() {
  return (
    <Alert severity='error'>
      <AlertTitle>Error</AlertTitle>
      {'Cannot create dashboard'}
    </Alert>
  );
}

export { AgencyDetailsError };
