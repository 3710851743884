import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { getLabelForRole } from './../UserContextProvider/roles';
import { CellDiff } from './CellDiff';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import getDuplicateUser from './getDuplicateUser';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  warningIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
  warningDiv: {
    display: 'flex',
  },
  title: {
    margin: 0,
    padding: theme.spacing(2),
    minWidth: '350px',
    background: '#CBD5DD',
    color: '#4A4A4A',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '18px',
    lineHeight: '22px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#213845',
  },
  dialogText: {
    fontSize: 14,
    lineHeight: '20px',
    color: '#4A4A4A',
  },
  grid: {
    paddingTop: theme.spacing(1),
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 14,
    lineHeight: '20px',
    color: '#4A4A4A',
  },
  details: {
    fontSize: 12,
    lineHeight: '16px',
    color: '#4A4A4A',
  },
  modalButtons: {
    lineHeight: '17px',
    textTransform: 'none',
  },
}));

export function ConfirmationDialog(props) {
  const {
    open,
    onClose,
    onConfirm,
    onNewConfirm,
    onChangeConfirm,
    updateData,
    users,
  } = props;
  const classes = useStyles();
  const { isEdit, oldData: oldUserData, newData: userData, isNew } = updateData;
  let firstName,
    oldFirstName,
    lastName,
    oldLastName,
    emailID,
    userType,
    oldUserType;
  if (oldUserData) {
    ({
      firstName: oldFirstName,
      lastName: oldLastName,
      userType: oldUserType,
    } = oldUserData);
  }
  if (userData) {
    ({ firstName, lastName, emailID, userType } = userData);
  }
  let duplicateUserDetails = {};

  const validateNewUser = (newUser) => {
    const duplicateUser = getDuplicateUser(users, newUser);
    if (duplicateUser.emailID) {
      duplicateUserDetails = duplicateUser;
      return true;
    } else {
      return false;
    }
  };
  const duplicateError = isEdit || isNew ? validateNewUser(userData) : false;
  const disableConfirm =
    validateNewUser(userData) && duplicateUserDetails.graviteeProfileCreated;
  const confirm = () => {
    if (isEdit) {
      onChangeConfirm(userData);
    } else if (isNew) {
      onNewConfirm(userData);
    } else {
      onConfirm(userData);
    }
    onClose();
  };
  let title;
  if (isEdit) {
    title = 'Confirm User Details Change';
  } else if (isNew) {
    title = 'Confirm New User Details';
  } else {
    title = 'Confirm User Details';
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth={true}>
      <DialogTitle className={classes.title}>
        <Typography variant='h6'>{title}</Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <List component='div' role='list' className={classes.dialogText}>
          <ListItem>
            <ListItemText
              primary='First Name'
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <CellDiff
                  editMode={isEdit}
                  lastValue={oldFirstName}
                  currentValue={firstName}
                />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='Last Name'
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <CellDiff
                  editMode={isEdit}
                  lastValue={oldLastName}
                  currentValue={lastName}
                />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <div className={classes.warningDiv}>
                  {duplicateError && (
                    <div>
                      <WarningIcon
                        fontSize='small'
                        className={classes.warningIcon}
                      />
                    </div>
                  )}
                  <div>Email ID</div>
                </div>
              }
              secondary={
                <>
                  {duplicateError ? (
                    <>
                      <Typography variant='body2'>
                        {duplicateUserDetails.emailID} has already been used for
                        user{' '}
                        {duplicateUserDetails.userID
                          ? duplicateUserDetails.userID
                          : ''}{' '}
                        - {duplicateUserDetails.firstName}{' '}
                        {duplicateUserDetails.lastName} with role{' '}
                        {getLabelForRole(duplicateUserDetails.userType)} .You
                        cannot have more than 1 active user with the same email.
                      </Typography>
                    </>
                  ) : (
                    <div>{emailID}</div>
                  )}
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='User Type'
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <CellDiff
                  editMode={isEdit}
                  lastValue={getLabelForRole(oldUserType)}
                  currentValue={getLabelForRole(userType)}
                />
              }
            />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant='contained'
          disableElevation
          className={classes.modalButtons}
        >
          Cancel
        </Button>

        <Button
          onClick={confirm}
          variant='contained'
          className={classes.modalButtons}
          color='primary'
          disableElevation
          disabled={disableConfirm}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onChangeConfirm: PropTypes.func.isRequired,
  onNewConfirm: PropTypes.func.isRequired,
  updateData: PropTypes.shape({
    isEdit: PropTypes.bool.isRequired,
    oldData: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      emailID: PropTypes.string.isRequired,
      userType: PropTypes.string.isRequired,
    }),
    newData: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      emailID: PropTypes.string.isRequired,
      userType: PropTypes.string.isRequired,
    }),
    isNew: PropTypes.bool.isRequired,
  }),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      userID: PropTypes.number.isRequired,
    })
  ),
};
