import React, { useContext } from 'react';
import { Title } from '../Header';
import { TrendsIcon } from '../Header/Icons';
import TrendsSkeleton from './TrendsSkeleton';
import TrendsError from './TrendsError';
import { useViewDetails } from '../useViewDetails';
import { TableauMarkSelectionView } from '../TableauView';
import { Route, useRouteMatch, Switch, Redirect } from 'react-router-dom';
import { AgencyDetails } from '../AgencyDetails/AgencyDetails';
import { useTenantDetails } from '../Header/useTenantDetails';
import { tenantLabels } from '../TableauView/tenantLabels';
import { UserContext } from '../UserContext';

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

const TRENDS_VIEW_KEY = 'Trends';

const Trends = () => {
  const match = useRouteMatch();
  const apiPath = '/api/metrics/v1/agencyTrends';
  const details = useViewDetails(apiPath);
  const { fetching, success, error, data } = details;
  const { data: tenantDetails } = useTenantDetails();
  const { user } = useContext(UserContext);
  const tenantApi = user.getTenantApi();

  const isAgencyAnalyticsHidden =
    tenantApi.isFunctionalityHidden(TRENDS_VIEW_KEY);
  if (isAgencyAnalyticsHidden) {
    const redirectionPath = tenantApi.getRedirectionPath();
    return <Redirect to={redirectionPath} />;
  }

  if (data && tenantDetails) {
    const { filter } = data;
    const { carrierLabel } = tenantDetails;
    let modifiedFilter;
    const parsedCarrierLabel = carrierLabel.replace(
      /[-/\\^$,*+?.()|[\]{}]/g,
      '\\$&'
    );

    if (typeof filter === 'string') {
      if (filter === '') {
        modifiedFilter = `${tenantLabels.CARRIERLABEL}=${parsedCarrierLabel}`;
      } else {
        modifiedFilter = `${filter}&${tenantLabels.CARRIERLABEL}=${parsedCarrierLabel}`;
      }
    }
    data.filter = modifiedFilter;
  }

  return (
    <>
      <Switch>
        <Route path={`${match.path}/agencydetails/:agencyName`}>
          <AgencyDetails backPath={match.url} />
        </Route>
        <Route path={`${match.path}`}>
          <Title icon={TrendsIcon} titleText={TRENDS_VIEW_KEY} />
          {fetching && <TrendsSkeleton />}
          {success && (
            <TableauMarkSelectionView
              {...data}
              {...dimensions}
              adjustHeight={true}
            />
          )}
          {error && <TrendsError />}
        </Route>
      </Switch>
    </>
  );
};

export default Trends;
