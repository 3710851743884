import React from 'react';
import {
  AgencyDataIcon,
  KPIIcon,
  SupportTicketsIcon,
  UsageAnalyticsIcon,
  UsageDataIcon,
} from '../Header/Icons';
import { AgencyData } from './AgencyData';
import { KPIS } from './KPI';
import { UsageData } from './UsageData';
const { SupportTickets } = require('../SupportTickets/SupportTickets');

const componentArray = [
  {
    key: 'Agency Data',
    component: <AgencyData />,
    icon: <AgencyDataIcon />,
  },
  {
    key: 'Usage Data',
    component: <UsageData />,
    icon: <UsageDataIcon />,
  },
  {
    key: 'KPI',
    component: <KPIS />,
    icon: <KPIIcon />,
  },
  {
    key: 'Support Tickets',
    component: <SupportTickets />,
    icon: <SupportTicketsIcon />,
  },
];

export default componentArray;
