import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { WidgetAccordion } from './WidgetAccordion';
import { DashboardWidget } from './DashboardWidget';
import { widgetProperties } from './WidgetProperties';
import { useContext } from 'react';
import { UserContext } from './../UserContext';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    color: '#4A4A4A',
    fontWeight: theme.typography.fontWeightBold,
  },
  titleimg: {
    width: 40,
    marginRight: 15,
  },
  content: {
    alignItems: 'center',
  },
  accordianMargin: {
    marginBottom: 20,
  },
}));

const TABLE_VIEW_KEY = 'Table View';

const TableView = (props) => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const tenantName = user.getTenant();
  const tenantApi = user.getTenantApi();

  const isGraphViewHidden = tenantApi.isFunctionalityHidden(TABLE_VIEW_KEY);
  if (isGraphViewHidden) {
    const redirectionPath = tenantApi.getRedirectionPath();
    return <Redirect to={redirectionPath} />;
  }

  let widgetArr = [];
  if (tenantName === 'hcp_indium' || tenantName === 'hcp_siaa') {
    widgetArr = widgetProperties.slice(0, -1);
  } else {
    widgetArr = widgetProperties;
  }

  const widgetView = widgetArr.map((widget, index) => {
    const { title, imgPath, apiPathTable } = widget;
    return (
      <div className={classes.accordianMargin} key={index}>
        {!tenantApi.isFunctionalityHidden(title) && (
          <WidgetAccordion ImgPath={imgPath} title={title} {...props}>
            <DashboardWidget apiPath={apiPathTable} {...props} />
          </WidgetAccordion>
        )}
      </div>
    );
  });
  return <>{widgetView}</>;
};

export { TableView };
