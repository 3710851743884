import React from 'react';
import { useContext } from 'react';
import { UserContext } from '../UserContext';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { DownloadIcon, OptionsIcon } from '../Header/Icons';
import clsx from 'clsx';
import { useSupportTickets } from './useSupportTickets';
import UsageAnalyticsSkeleton from '../UsageAnalytics/UsageAnalyticsSkeleton';
import { useRef } from 'react';
import { useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import columnDefs from './columnDefs';
import './style.css';

const useStyles = makeStyles((theme) => ({
  buttonDiv: {
    display: 'flex',
    justifyContent: 'end',
    height: 40,
  },
  download: {
    borderRadius: 4,
    height: 30,
    textTransform: 'none',
  },
  downloadButton: {
    background: 'rgb(25,88,128,0.2)',
    '&:hover': {
      background: '#d5dfe7',
    },
  },
  downloadButtonDisabled: {
    opacity: '0.5',
  },
  buttonLabel: {
    color: '#195880',
    fontSize: 14,
    fontWeight: 500,
  },
  details: {
    width: 'auto',
    height: 500,
    padding: 0,
  },
}));

const SupportTickets = () => {
  const classes = useStyles();
  const gridRef = useRef();
  const SUPPORT_TICKETS_VIEW_NAME = 'Support Tickets';

  let sectionList = [];
  const { user } = useContext(UserContext);
  const { tenantDetails } = user;
  const displayConfig = tenantDetails
    ? JSON.parse(tenantDetails.displayConfig)
    : undefined;
  const views = displayConfig ? displayConfig.views : undefined;

  if (views) {
    views.forEach((view) => {
      const { key, config } = view;
      if (key === SUPPORT_TICKETS_VIEW_NAME) {
        const { jqlList } = config;
        jqlList.forEach((jqlObject) => {
          const { section } = jqlObject;
          sectionList.push(section);
        });
      }
    });
  }

  const baseAPI = `/api/jira/v1/getRequestsOfNetwork?key=`;
  
  const { fetching, data } = useSupportTickets(baseAPI, sectionList);

  const onDownload = useCallback(() => {
    gridRef.current.api.exportDataAsExcel({});
  }, []);

  return (
    <>
      <div>
        <div className={classes.buttonDiv}>
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            endIcon={<OptionsIcon />}
            classes={{
              root: classes.downloadButton,
              label: classes.buttonLabel,
            }}
            className={clsx({
              [classes.download]: true,
              [classes.downloadButtonDisabled]: fetching,
            })}
            disabled={fetching}
            onClick={onDownload}
          >
            Download
          </Button>
        </div>

        {fetching && <UsageAnalyticsSkeleton />}

        {!fetching && (
          <div className={classes.details} id="gridContainer">
            <AgGridReact
              className="ag-theme-alpine"
              rowData={data}
              ref={gridRef}
              columnDefs={columnDefs}
              rowGroupPanelShow={'always'}
              tooltipShowDelay={0}
              tooltipHideDelay={4000}
            />
          </div>
        )}
      </div>
    </>
  );
};

export { SupportTickets };
