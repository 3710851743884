import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
    minWidth: '350px',
    background: '#CBD5DD',
    color: '#4A4A4A',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '18px',
    lineHeight: '22px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#213845',
  },
  dialogText: {
    fontSize: 14,
    lineHeight: '20px',
    color: '#4A4A4A',
  },
  modalButtons: {
    lineHeight: '17px',
    textTransform: 'none',
  },
}));

export function ValidationErrorDialog(props) {
  const classes = useStyles();
  const { open, onClose, errorMessages } = props;
  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm'>
      <DialogTitle className={classes.title}>
        <Typography variant='h6'>Invalid User Details</Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <List component='div' role='list' className={classes.dialogText}>
          {errorMessages.map((errorMessage, index) => (
            <ListItem key={index}>
              <ListItemText primary={errorMessage} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant='contained'
          className={classes.modalButtons}
          color='primary'
          disableElevation
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ValidationErrorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  errorMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
};
