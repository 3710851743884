import { useState, useEffect } from 'react';
import axios from 'axios';

function useSupportTickets(baseAPI, sectionList) {
  let requests = sectionList.map((element) => {
    return axios.get(`${baseAPI}${element}`);
  });
  const startPosition = baseAPI.length;

  const [tickets, setTickets] = useState({
    fetching: false,
    error: false,
    success: false,
    data: [],
  });

  useEffect(() => {
    const getData = async () => {
      let value = [];
      setTickets({
        fetching: true,
        error: false,
        success: false,
        data: [],
      });

      try {
        const responses = await Promise.all(requests);
        responses.map((element) => {
          const { config, data } = element;
          const { url } = config;
          const { data: responseData } = data;
          const sectionName = url.substr(startPosition);
          if (Array.isArray(responseData)) {
            responseData.forEach((element) => {
              const newElement = { ...element, groupName: sectionName };
              value.push(newElement);
            });
          }
        });

        setTickets({
          fetching: false,
          error: false,
          success: true,
          data: value,
        });
      } catch (error) {
        setTickets({
          fetching: false,
          error: true,
          success: false,
          data: [],
        });
      }
    };

    getData();
  }, [baseAPI]);

  return tickets;
}

export { useSupportTickets };
