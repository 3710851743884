import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { TitleDashboard } from '../Header';
import { DashboardIcon } from '../Header/Icons';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { GraphView } from './GraphView';
import { TableView } from './TableView';
import {
  Route,
  useRouteMatch,
  useHistory,
  Switch,
  Redirect,
} from 'react-router-dom';
import { AgencyDetails } from '../AgencyDetails/AgencyDetails';
import { AgencyAnalytics } from '../AgencyAnalytics/AgencyAnalytics';
import { UserContext } from '../UserContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#FFFFFF',
  },
  appBar: {
    backgroundColor: '#EDF2F5',
  },
  indicator: {
    top: 0,
    borderRadius: 2,
  },
  tab: {
    color: '#4A4A4A',
    fontSize: 18,
    textTransform: 'none',
  },
  selectedTab: {
    color: '#2D6089',
    background: '#FFFFFF',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 18,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  const classes = useStyles();
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      className={classes.tab}
      classes={{
        selected: classes.selectedTab,
      }}
      {...props}
    />
  );
}

const DASHBOARD_KEY = 'Dashboard';
const GRAPHICAL_VIEW_KEY = 'Graphical View';
const TABLE_VIEW_KEY = 'Table View';
const AGENCY_ANALYTICS_PATH = '/home/agencyanalytics';

export function Dashboard() {
  const match = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState();
  const { user } = useContext(UserContext);
  const tenantApi = user.getTenantApi();

  const isDashboardHidden = tenantApi.isFunctionalityHidden(DASHBOARD_KEY);
  if (isDashboardHidden) {
    const redirectionPath = tenantApi.getRedirectionPath();
    return <Redirect to={redirectionPath} />;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const goToAgencyDetails = (agencyName) => {
    history.push(`${match.url}/agencydetails/${agencyName}`);
  };

  const goToAgencyAnalytics = () => {
    history.push(AGENCY_ANALYTICS_PATH);
  };

  return (
    <>
      <Switch>
        <Route path={`${match.path}/agencydetails/:agencyName`}>
          <AgencyDetails backPath={match.url} />
        </Route>
        <Route path={AGENCY_ANALYTICS_PATH}>
          <AgencyAnalytics />
        </Route>
        <Route path={`${match.path}`}>
          <TitleDashboard
            icon={DashboardIcon}
            titleText={DASHBOARD_KEY}
            goToAgencyAnalytics={goToAgencyAnalytics}
          />
          <div className={classes.root}>
            <AppBar position="static" elevation={0} className={classes.appBar}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="nav tabs example"
                textColor="primary"
                indicatorColor="primary"
                classes={{
                  indicator: classes.indicator,
                }}
              >
                {!tenantApi.isFunctionalityHidden(GRAPHICAL_VIEW_KEY) && (
                  <LinkTab label={GRAPHICAL_VIEW_KEY} {...a11yProps(0)} />
                )}
                {!tenantApi.isFunctionalityHidden(TABLE_VIEW_KEY) && (
                  <LinkTab label={TABLE_VIEW_KEY} {...a11yProps(1)} />
                )}
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <GraphView
                goToAgencyDetails={goToAgencyDetails}
                expanded={expanded}
                setExpanded={setExpanded}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TableView
                goToAgencyDetails={goToAgencyDetails}
                expanded={expanded}
                setExpanded={setExpanded}
              />
            </TabPanel>
          </div>
        </Route>
      </Switch>
    </>
  );
}
