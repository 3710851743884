import React, { useEffect, useContext } from 'react';
import { UserContext } from './../UserContext';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';

const setView = (pathname) => {
  let pathToSet = pathname;
  ReactGA.set({ page: pathToSet });
  ReactGA.pageview(pathToSet);
};

const initReactGA = (trackingId, user) => {
  const debug = process.env.NODE_ENV !== 'production';
  ReactGA.initialize(trackingId, {
    debug: debug,
    titleCase: false,
  });
  ReactGA.set({
    userId: `${user.getTenant()}-${user.getTenantLevelUserId()}`,
    dimension1: user.getTenant(),
    dimension2: user.getTenantLevelUserId(),
    dimension3: user.getTopRole(),
  });
  setView(window.location.pathname);
};

export function GoogleAnalytics(props) {
  const history = useHistory();
  const { user } = useContext(UserContext);
  useEffect(() => {
    const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
    if (trackingId) {
      initReactGA(trackingId, user);
      history.listen((location) => {
        setView(location.pathname);
      });
    }
  }, [history, user]);
  return <>{props.children}</>;
}
