import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export default function TitleSkeleton() {
  return (
    <div>
      <Skeleton variant="rect" width={128} height={32} />
    </div>
  );
}
