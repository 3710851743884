import validator from 'validator';
import { getAssignableRoleKeys } from './../UserContextProvider/roles';

const MAX_NAME_LENGTH = 45;
const roleKeys = getAssignableRoleKeys();

const validateEmail = (emailID, fieldName) => {
  let error = false;
  let errorMessages = [];
  if (typeof emailID !== 'string') {
    error = true;
    errorMessages.push(`${fieldName} is required`);
  } else if (
    !validator.isEmail(emailID, {
      allow_utf8_local_part: false,
    })
  ) {
    error = true;
    errorMessages.push(`${fieldName} is invalid`);
  } else if (!validator.isLength(emailID, { min: 1, max: MAX_NAME_LENGTH })) {
    error = true;
    errorMessages.push(
      `${fieldName} cannot be greater than ${MAX_NAME_LENGTH} characters`
    );
  }
  return { error, errorMessages };
};

const validateName = (name, fieldName) => {
  let error = false;
  let errorMessages = [];
  if (typeof name !== 'string') {
    error = true;
    errorMessages.push(`${fieldName} is required`);
  } else if (validator.isEmpty(name, { ignore_whitespace: true })) {
    error = true;
    errorMessages.push(`${fieldName} cannot be empty`);
  } else if (!validator.isAscii(name)) {
    error = true;
    errorMessages.push(`${fieldName} cannot contain special characters`);
  } else if (!validator.isLength(name, { min: 1, max: MAX_NAME_LENGTH })) {
    error = true;
    errorMessages.push(
      `${fieldName} cannot be greater than ${MAX_NAME_LENGTH} characters`
    );
  }
  return { error, errorMessages };
};

const validateUserType = (userType, fieldName) => {
  let error = false;
  let errorMessages = [];
  if (typeof userType !== 'string') {
    error = true;
    errorMessages.push(`${fieldName} is required`);
  } else if (!validator.isIn(userType, roleKeys)) {
    error = true;
    errorMessages.push(`${fieldName} is invalid`);
  }
  return { error, errorMessages };
};

export const validateEditedRow = (newData) => {
  let error = false;
  let errorMessages = [];
  const { firstName, lastName, emailID, userType } = newData;
  const { error: firstNameError, errorMessages: firstNameErrorMessages } =
    validateName(firstName, 'First name');
  const { error: lastNameError, errorMessages: lastNameErrorMessages } =
    validateName(lastName, 'Last name');
  const { error: emailIDError, errorMessages: emailIDErrorMessages } =
    validateEmail(emailID, 'Email ID');
  const { error: userTypeError, errorMessages: userTypeErrorMessages } =
    validateUserType(userType, 'Role');
  error = firstNameError || lastNameError || emailIDError || userTypeError;
  errorMessages = [
    ...firstNameErrorMessages,
    ...lastNameErrorMessages,
    ...emailIDErrorMessages,
    ...userTypeErrorMessages,
  ];
  return { error, errorMessages };
};
