import React from 'react';
import UsageAnalyticsSkeleton from './UsageAnalyticsSkeleton';
import UsageAnalyticsError from './UsageAnalyticsError';
import { useViewDetails } from '../useViewDetails';
import { TableauMarkSelectionView } from '../TableauView';

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

export function AgencyData(props) {
  const agencyDataPath = `/api/metrics/v1/agencyData`;
  const agencyData = useViewDetails(agencyDataPath);
  const { fetching, success, error, data } = agencyData;

  return (
    <div>
      {fetching && <UsageAnalyticsSkeleton />}
      {success && (
        <TableauMarkSelectionView
          {...data}
          {...dimensions}
          adjustHeight={true}
        />
      )}
      {error && <UsageAnalyticsError />}
    </div>
  );
}
