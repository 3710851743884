import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { getLabelForRole } from './../UserContextProvider/roles';
import { getReportsToMapping } from './../UserManagement/columns';

export function CompleteProfileConfirmation(props) {
  const { open, onClose, onConfirm, userData, users, hideRole } = props;
  let userID, userType;
  if (userData) {
    ({ userID, userType } = userData);
  }
  const idOnlyUserMapping = getReportsToMapping(users, true);
  const confirm = () => {
    onConfirm(userData);
    onClose();
  };
  const showRole = !(hideRole === true);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Profile Details</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Your account will have the following details. This action is not
          reversible.
        </DialogContentText>
        <List component='div' role='list'>
          <ListItem>
            <ListItemText
              primary='Role'
              secondary={getLabelForRole(userType)}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary='ID' secondary={idOnlyUserMapping[userID]} />
          </ListItem>
          {showRole && (
            <ListItem>
              <ListItemText
                primary='Role'
                secondary={getLabelForRole(userType)}
              />
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={confirm} color='primary'>
          Confirm
        </Button>
        <Button onClick={onClose} color='primary'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CompleteProfileConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      userID: PropTypes.number.isRequired,
      userInitials: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })
  ),
  userData: PropTypes.shape({
    userID: PropTypes.number.isRequired,
    userType: PropTypes.string.isRequired,
  }),
  hideRole: PropTypes.bool.isRequired,
};
